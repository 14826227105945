import React from 'react';

class Warning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: !props.width ? 11 : props.width,
      height: !props.height ? 11 : props.height,
      color: !props.color ? '#fff' : props.color,
    };
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={this.state.width} height={this.state.height} viewBox="0 0 11 11">
        <path
          id="exclamation-circle-solid"
          d="M19,13.5A5.5,5.5,0,1,1,13.5,8,5.5,5.5,0,0,1,19,13.5Zm-5.5,1.109a1.02,1.02,0,1,0,1.02,1.02A1.02,1.02,0,0,0,13.5,14.609Zm-.969-3.667.165,3.016a.266.266,0,0,0,.266.252h1.077a.266.266,0,0,0,.266-.252l.165-3.016a.266.266,0,0,0-.266-.281H12.8A.266.266,0,0,0,12.531,10.942Z"
          transform="translate(-8 -8)"
          fill="#1a1f26"
        />
      </svg>
    );
  }
}

export default Warning;
