import { requestFetch, REQUEST_TYPE } from '../utils/requestFetch';
import BASIC_CONSTANT from '../constants/basic.constants.js/basic.constants.js';
import SystemError from '../utils/exception/SystemError';

export default class VersionClass {
  constructor(data = {}) {
    this.setData(data);
  }
  setData(data) {
    Object.keys(data).map(key => {
      this[key] = data[key];
    });
  }
  getData(key) {
    return this[key];
  }

  async getVersion() {
    try {
      const result = await requestFetch(`${BASIC_CONSTANT.BASE_URL}version/newest`, REQUEST_TYPE.GET, {}, {});
      if (result.code === 200) return result.data;
    } catch (e) {
      return SystemError.processRequestError(e);
    }
  }
}
