import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'utils/with-i18next';
import BASIC_CONSTANT from 'constants/basic.constants.js/basic.constants.js';
import VersionClass from 'classes/VersionClass';

const isServer = typeof window === 'undefined';
const platform = !isServer ? window.navigator.platform : null;
const cpuClass = !isServer ? window.navigator.cpuClass : null;

const DownloadBtn = props => {
  let buttonCase = {
    window: {
      title: 'forWindow',
      t: 'windows',
      download:
        platform == 'win32' && cpuClass == 'x86'
          ? `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-Setup-${props.version}.exe`
          : `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-Setup-${props.version}.exe`,
    },
    mac: {
      title: 'forMac',
      t: 'mac',
      download: `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-${props.version}.dmg`,
    },
  };

  const conversasion = url => {
    window.open('https://dodlab.notion.site/aebd5a56ed484c59b4dd776e563709bb', '_blank');
    var callback = function() {
      if (typeof url != 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      send_to: 'AW-766068314/l_ZcCJXz-v8BENqMpe0C',
      event_callback: callback,
    });
  };

  const { type, className } = props;
  const buttonCaseData = buttonCase[props.type];
  const { t } = props;

  return (
    <a
      href={buttonCaseData.download}
      download
      id={buttonCaseData.title}
      className={`btn btn-white btn-down ${className ? className : ''}`}
      onClick={() => conversasion(buttonCaseData.download)}>
      {t(buttonCaseData.t)} (ver {props.version})
    </a>
  );
};

// class DownloadBtn extends React.Component {
//   constructor(props) {
//     super(props);
//     console.log(props);
//     this.state = {
//       version: '',
//     };
//     this.buttonCase = {
//       window: {
//         title: 'forWindow',
//         t: 'windows',
//         download:
//           platform == 'win32' && cpuClass == 'x86'
//             ? `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-${this.props.version}.exe`
//             : `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-${this.props.version}.exe`,
//       },
//       mac: {
//         title: 'forMac',
//         t: 'mac',
//         download: `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-${this.props.version}.dmg`,
//       },
//     };
//   }

//   async componentDidMount() {
//     const vClass = new VersionClass();
//     const version = await vClass.getVersion();

//     this.setState({ version });
//     console.log(this.state.version);
//   }

//   conversasion(url) {
//     var callback = function() {
//       if (typeof url != 'undefined') {
//         window.location = url;
//       }
//     };
//     window.gtag('event', 'conversion', {
//       send_to: 'AW-766068314/l_ZcCJXz-v8BENqMpe0C',
//       event_callback: callback,
//     });
//     return false;
//   }

//   render() {
//     const { type, className } = this.props;
//     const buttonCaseData = this.buttonCase[this.props.type];
//     const { t } = this.props;

//     return (
//       this.state.version !== '' && (
//         <a
//           href={buttonCaseData.download}
//           download
//           id={buttonCaseData.title}
//           className={`btn btn-white btn-down ${className ? className : ''}`}
//           onClick={() => this.conversasion(buttonCaseData.download)}>
//           {t(buttonCaseData.t)} (ver {this.props.version})
//         </a>
//       )
//     );
//   }
// }
// DownloadBtn.propTypes = {
//   t: PropTypes.func,
// };

export default withTranslation('download')(DownloadBtn);
