import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import PageComponent from './PageComponent';
import NotificationActions from '../redux/NotificationActions';
import UserActions from '../redux/UserActions';
import Layout from '../components/Layout';
import Logo from '../assets/img/Logo';
import main_img from '../assets/img/main_img.png';
import DownloadBtn from '../components/DownloadBtn';
import { Global, css } from '@emotion/react';
import Loading from '../components/Common/Loading';
import { withTranslation } from 'utils/with-i18next';
import BASIC_CONSTANT from '../constants/basic.constants.js/basic.constants.js';
import VersionClass from 'classes/VersionClass';
import gtag from '../components/Analytics/gtag';
import Warning from '../assets/img/warning';
import NextHead from 'next/head';
import YouTube from 'react-youtube';

const step1 = 'https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/video/step1.mp4';
const step2_4 = 'https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/video/step2_4.mp4';
const step3 = 'https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/video/step3.mp4';
const step4 = 'https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/video/step4.mp4';
class Index extends PageComponent {
  constructor() {
    super();
    this.state = {
      now: new Date().getHours(),
      loading: true,
      version: null,
    };
    this.version = new VersionClass();
    this.heroRef = React.createRef();
    this.opts = {
      playerVars: {
        autoplay: 1,
        mute: 1,
        controls: 0,
        iv_load_policy: 2,
        showinfo: 0,
        loop: 1,
        disablekb: 1,
        playsinline: 1,
        rel: 0,
        fs: 0,
        modestbranding: 1,
      },
    };
  }

  componentDidUpdate() {
    if (this.heroRef.current) this.setState({ heroHeight: this.heroRef.current.offsetHeight });
    window.addEventListener('scroll', this.onScroll);
  }

  componentDidMount() {
    //
    super.componentDidMount();
    const { now } = this.state;
    if (now >= 6 && now < 20) {
      this.setState({ nhr: true });
    }
    this.getOS();
    this.getVersion();
    this.videoHandle();
    this.setState({ loading: false });
  }

  playHandle(playPromise) {
    if (playPromise !== undefined) {
      playPromise
        .then(_ => {})
        .catch(error => {
          console.log(error);
        });
    }
  }

  videoHandle() {
    let target = document.getElementsByClassName('step');

    window.addEventListener('scroll', e => {
      let scrollY = window.scrollY;

      for (const element of target) {
        let targetOffsetTop = element.offsetTop - 850;
        if (scrollY >= targetOffsetTop) {
          let video = element.childNodes[0];
          let playPromise = video.play();
          this.playHandle(playPromise);
        }
      }
    });

    if (window.innerWidth > 650) {
      window.addEventListener('scroll', e => {
        let scrollY = window.scrollY;
        for (const element of target) {
          let targetOffsetTop = element.offsetTop - 1800;
          if (scrollY >= targetOffsetTop) {
            let video = element.childNodes[0];
            let playPromise = video.play();
            this.playHandle(playPromise);
          }
        }
      });
    }
  }

  getOS() {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let os = 'Windows';

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    }
    this.setState({ os });
  }

  async getVersion() {
    let version = localStorage.getItem('version');

    if (version) {
      version = JSON.parse(version);
      this.setState({ version });
    }
  }

  onScroll = e => {
    const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
    this.setState({ scrollTop });
    const { now, heroHeight } = this.state;
    if (heroHeight < scrollTop) {
      this.setState({ nhr: false });
    } else if (heroHeight >= scrollTop && now >= 6 && now < 20) {
      this.setState({ nhr: true });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll);
  };

  eventHandle(reverse) {
    const isServer = typeof window === 'undefined';
    let action;
    if (this.state.os === 'Mac') {
      action = 'mac_';
      if (reverse) action = 'win_';
    } else {
      action = 'win_';
      if (reverse) action = 'mac_';
    }

    if (!isServer) {
      gtag({
        action: `${action}download`,
        category: 'file_download',
        label: `${action}user`,
        value: 'user',
      });
    }
  }

  render() {
    const { nhr } = this.state;
    const { t } = this.props;
    // console.log(this.state);
    return (
      <>
        {nhr && (
          <Global
            styles={css`
              header {
                background: #ddfc10;
                .header-inner {
                  border-color: #1a1f26;
                }
                a {
                  color: #1a1f26;
                }
                svg path {
                  fill: #1a1f26;
                }
                .underLine::before,
                .burger:before,
                .burger:after,
                .bun:before,
                .bun:after {
                  background: #1a1f26 !important;
                }
                .userBtn {
                  background-color: #1a1f26 !important;
                  color: #fafafa;
                  .underLine::before {
                    background: #fafafa !important;
                  }
                }
                .popup-menu a {
                  color: #fff;
                }
                .contact .lang {
                  color: #1a1f26;
                }
              }
            `}
          />
        )}
        <Layout>
          <NextHead>
            <title>BGMs - YouTube 플레이리스트 공유 및 원격 재생</title>
            <meta
              name="description"
              content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요."
            />
            <meta property="og:title" content="BGMs - YouTube 플레이리스트 공유 및 원격 재생" />
            <meta name="subject" content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요." />
            <meta
              property="og:description"
              content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요."
            />
            <meta property="og:url" content="https://bgms.live" />

            {/* <!-- twitter meta --> */}
            <meta
              name="twitter:image"
              content="https://bgms-bucket.s3.ap-northeast-2.amazonaws.com/channel/image/bgmsMetaImg.jpg"
            />
            <meta name="twitter:domain" content="https://bgms.live" />

            <meta name="twitter:title" content="BGMs - YouTube 플레이리스트 공유 및 원격 재생" />
            <meta
              name="twitter:description"
              content="BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요."
            />
          </NextHead>
          {this.state.loading ? (
            <Loading />
          ) : (
            <div className="index">
              <section className={`hero ${nhr ? 'leon' : ''}`} ref={this.heroRef}>
                <div className="logo-wrap">
                  <Logo className="hero-logo" />
                  <span className="wow fadeInRight">{t('hero.fadeInRight')}</span>
                </div>
                <div className="main-desc">
                  <div className="marquee">
                    <div>
                      <span>{t('hero.main-desc')}</span>
                      <span>{t('hero.main-desc')}</span>
                      <span>{t('hero.main-desc')}</span>
                    </div>
                    <div>
                      <span>{t('hero.main-desc')}</span>
                      <span>{t('hero.main-desc')}</span>
                      <span>{t('hero.main-desc')}</span>
                    </div>
                  </div>
                </div>
                {/* <div className="btn_wrap wow fadeInUp" data-wow-delay=".5s">
                  <DownloadBtn type="window" />
                  <DownloadBtn type="mac" />
                </div> */}
              </section>
              <section>
                <div className="bgmsIs wrapB center">
                  <div className="desc">
                    <h2 className="wow fadeInUp whiteSpace-pre">{t('section.1.h2')}</h2>
                    <p className="wow fadeInUp whiteSpace-pre" data-wow-delay=".4s">
                      {t('section.1.p')}
                    </p>
                  </div>
                  <div className="main-video">
                    <YouTube
                      id="player"
                      videoId="SCzZyYEA2J0"
                      opts={this.opts}
                      onReady={e => {
                        if (e.target) e.target.playVideo();
                      }}
                      onEnd={e => e.target.playVideo()}
                    />
                  </div>
                  {/* <img className="with100 wow fadeInUp" data-wow-delay=".65s" src={main_img} alt="" /> */}
                </div>
              </section>
              {/* tutorial */}
              <section className="tutorial-section bg-odd">
                <div className="bgmsIs wrapB flex-end">
                  <div className="tutorial-desc">
                    <span className="wow fadeInRight"> {t('bgmsIs')} </span>
                    <h2 className="wow fadeInRight whiteSpace-pre">{t('section.2.h2')}</h2>
                    <p className="wow fadeInRight whiteSpace-pre" data-wow-delay=".4s">
                      {t('section.2.p')}
                    </p>
                  </div>
                  <div className="step wow fadeInLeft margin-right">
                    <video loop muted playsInline>
                      <source src={step1} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section>
              <section className="tutorial-section">
                <div className="bgmsIs wrapB flex-start">
                  <div className="tutorial-desc">
                    <span className="wow fadeInLeft"> {t('bgmsIs')} </span>
                    <h2 className="wow fadeInLeft whiteSpace-pre">{t('section.3.h2')}</h2>
                    <p className="wow fadeInLeft whiteSpace-pre" data-wow-delay=".4s">
                      {t('section.3.p')}
                    </p>
                  </div>
                  <div className="step wow fadeInRight margin-left">
                    <video loop muted playsInline>
                      <source src={step2_4} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section>
              <section className="tutorial-section bg-odd">
                <div className="bgmsIs wrapB flex-end">
                  <div className="tutorial-desc">
                    <span className="wow fadeInRight"> {t('bgmsIs')} </span>
                    <h2 className="wow fadeInRight whiteSpace-pre">{t('section.4.h2')}</h2>
                    <p className="wow fadeInRight whiteSpace-pre" data-wow-delay=".4s">
                      {t('section.4.p')}
                    </p>
                    <p className="wow fadeInRight whiteSpace-pre margin-top" data-wow-delay=".4s">
                      {t('section.4.p1')}
                    </p>
                  </div>
                  <div className="step wow fadeInLeft margin-right">
                    <video loop muted playsInline>
                      <source src={step3} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section>
              <section className="tutorial-section">
                <div className="bgmsIs wrapB flex-start margin-right">
                  <div className="tutorial-desc">
                    <span className="wow fadeInLeft"> {t('bgmsIs')} </span>
                    <h2 className="wow fadeInLeft whiteSpace-pre">{t('section.5.h2')}</h2>
                    <p className="wow fadeInLeft whiteSpace-pre" data-wow-delay=".4s">
                      {t('section.5.p')}
                    </p>
                  </div>
                  <div className="step wow fadeInRight margin-left">
                    <video loop muted playsInline src={step4}>
                      <source src={step4} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section>

              <section>
                <div className="slide-box wrapB">
                  <div
                    className="item"
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }}>
                    {/* </Marquee> */}
                    <div className="marquee">
                      <div className="marquee1">
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                      </div>
                      <div className="marquee2">
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                        <span className="slide-text ">{t('bottom-slide-desc')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="download_btn_wrap">
                    <div className="btn_wrap wow fadeInUp" data-wow-delay=".5s" onClick={() => this.eventHandle()}>
                      {this.state.os === 'Windows' ? (
                        <DownloadBtn type="window" version={this.state.version?.win} />
                      ) : (
                        <DownloadBtn type="mac" version={this.state.version?.mac} />
                      )}
                    </div>
                    <p className="down-info wow fadeInUp" data-wow-delay=".5s">
                      <a
                        href={
                          this.state.os === 'Windows'
                            ? `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-${this.state.version?.mac}.dmg`
                            : `${BASIC_CONSTANT.DOWNLOAD_URL}BGMs-Setup-${this.state.version?.win}.exe`
                        }
                        onClick={() => this.eventHandle('reverse')}>
                        <Warning />
                        혹시&nbsp;{this.state.os === 'Windows' ? 'Mac' : 'Windows'} 버전 이 필요하신가요?
                      </a>
                    </p>
                    <p className="down-info wow fadeInUp" data-wow-delay=".5s">
                      {/* 경고 메시지 창이 뜨면서 리모컨 실행이 안되나요? */}
                      <a
                        href="https://dodlab.notion.site/aebd5a56ed484c59b4dd776e563709bb"
                        target="_blank"
                        rel="noopener noreferrer">
                        <Warning />
                        보안 경고 메시지로 인해 설치가 어려우신가요?
                      </a>
                    </p>
                  </div>
                </div>
              </section>
            </div>
          )}
        </Layout>
      </>
    );
  }
}

Index.propTypes = {
  UserRedux: PropTypes.object,
  NotificationRedux: PropTypes.object,
  t: PropTypes.func,
};

export default connect(
  state => state,
  (dispatch, ownProps) => {
    return { ...UserActions(dispatch, ownProps), ...NotificationActions(dispatch, ownProps) };
  }
)(withTranslation('index')(Index));
