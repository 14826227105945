import React from 'react';

const Loading = props => {
  return (
    <div className="loading-wrap">
      <div className="loading-box">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
