const gtag = props => {
  const { action, category, label, value } = props;
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export default gtag;
